import React from 'react'
import { graphql } from 'gatsby'
import {
  Container,
  Row,
  Col,
} from 'reactstrap'
import Header from '../components/pages/common/header'
import Layout from '../components/layout'
import Page from '../components/page'
import Form from '../components/contact/form'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import Map from '../components/pages/common/map'

const ContactPage = ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <title>Contact | Whickham Thorns Outdoor Activity Centre</title>
      </Helmet>
      <Header
        headerImage={data.headerImage}
        title="Contact Us"
        height="20rem"
      />
      <Page>
        <Container>
          <Row>
            <Col sm={12} md="5">
              <aside>
                <h4>Address</h4>
                <address>
                  <p>Whickham Thorns</p>
                  <p>Market Lane,</p>
                  <p>Gateshead</p>
                  <p>NE11 9NX</p>
                </address>

                <h4>Directions</h4>
                <p>
                  Whickham Thorns is off the A1 Western Bypass opposite Intu Metrocentre. 
                  Take the Dunston/Whickham exit then turn south to the traffic lights where you need to turn right.
                </p>

                <Map />
              </aside>
            </Col>
            <Col sm={12} md="7">
              {data.site.siteMetadata.formEnabled == true && <Form />}
              {data.site.siteMetadata.formEnabled != true && (
                <div>
                  In order to make an enqiry, please email us at <a href="mailto:activities@whickhamthorns.co.uk">activities@whickhamthorns.co.uk</a>.
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </Page>
    </Layout>
  )
}

export const query = graphql`
  query ContactQuery {
    site {
      siteMetadata {
        formEnabled
      }
    }
    headerImage: file(relativePath: { regex: "/background/" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 70)
      }
    }
  }
`

export default ContactPage
